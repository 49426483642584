import React from 'react'
import { ROUTE_KINE, ROUTE_MASSAGE } from '../../core/routing/routing';
import { ReactComponent as Logo } from "../../assets/icons/logo-helix.svg";
import './_selector.css'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import FadeIn from '../shared/animations/fadeIn';

const SelectorScene = () => {
    return (
        <section className="row m-0 min-full-height pb-5 position-relative text-primary justify-content-center align-items-center">
            <div className="container d-flex flex-column align-items-center">
                <div className="logo-wrapper">
                    <Logo className="" />
                    <h1 className="sr-only">Helix, een groepspraktijk met kinesitherapie en zorgmassage door Céline Charlier en Debby De Vos.</h1>
                </div>

                <div className="d-flex flex-column align-items-center justify-content-center flex-lg-row col-12 mt-4">
                    <FadeIn delay="250">
                        <Link
                            to={ROUTE_KINE}
                            className="btn btn-primary d-flex align-items-center mx-2 my-2"
                        >
                            Ga naar kinesitherapie Céline Charlier
                        </Link>
                    </FadeIn>

                    <FadeIn delay="750">
                        <Link
                            to={ROUTE_MASSAGE}
                            className="btn btn-secondary d-flex align-items-center mx-2 my-2"
                        >
                            Ga naar zorgmassage Debby De Vos
                        </Link>
                    </FadeIn>
                </div>
            </div>
        </section>
    )
}

export default SelectorScene