import {
  ROUTE_APP,
  ROUTE_CONTACT,
  ROUTE_KINE,
  ROUTE_TREATMENT,
} from "../../../core/routing/routing";
import React, { Component, Fragment } from "react";

import Icon from "../icon/Icon";
import { NavLink } from "react-router-dom";
import { t } from "../../../core/localization/source";

export default class Nav extends Component {
  render() {
    window.scrollTo(0, 0);
    return (
      <nav className="nav" id="navbar">
        <Fragment>
          <NavLink className="text-decoration-none" exact to={ROUTE_KINE} activeClassName="selected">
            {t("navigation.home")}
          </NavLink>
          <NavLink className="text-decoration-none" to={ROUTE_TREATMENT} activeClassName="selected">
            {t("navigation.treatments")}
          </NavLink>
          <NavLink className="text-decoration-none" exact to={ROUTE_CONTACT} activeClassName="selected">
            {t("navigation.contact")}
          </NavLink>
          <NavLink
            exact
            to={ROUTE_APP}
            activeClassName="selected"
            className="logo text-decoration-none"
            title="Céline Charlier | Kinesitherapie"
          >
            <Icon name="logo" />
          </NavLink>
        </Fragment>
      </nav>
    );
  }
}
