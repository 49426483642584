import { dutchRoutes, frenchRoutes } from "./variables";

import { Localization } from "../localization/source/localization";
import { createBrowserHistory } from "history";

export const history = createBrowserHistory({
  basename: "/"
});

let ROUTE_APP = `/`;
let ROUTE_TREATMENT;
let ROUTE_TREATMENT_DETAIL = ROUTE_TREATMENT + "/:treatment";
let ROUTE_CONTACT;

let ROUTE_KINE;
let ROUTE_MASSAGE;

export const setLanguage = lang => {
  localStorage.setItem("language", lang);
  checkRoutes();
  let language = localStorage.getItem("language");
  if (language === "fr") {
    console.log("Set all routes in FRENCH");
    Localization.setLocale("fr");
    ROUTE_KINE = `/${frenchRoutes.ROUTE_KINE}`;
    ROUTE_TREATMENT = `${ROUTE_KINE}/${frenchRoutes.ROUTE_TREATMENT}`;
    ROUTE_TREATMENT_DETAIL = `${ROUTE_KINE}/${ROUTE_TREATMENT}/:treatment`;
    ROUTE_CONTACT = `${ROUTE_KINE}/${frenchRoutes.ROUTE_CONTACT}`;
    ROUTE_MASSAGE = `/${frenchRoutes.ROUTE_MASSAGE}`;
  } else {
    Localization.setLocale("nl");
    ROUTE_KINE = `/${dutchRoutes.ROUTE_KINE}`;
    ROUTE_TREATMENT = `${ROUTE_KINE}/${dutchRoutes.ROUTE_TREATMENT}`;
    ROUTE_TREATMENT_DETAIL = `${ROUTE_TREATMENT}/:treatment`;
    ROUTE_CONTACT = `${ROUTE_KINE}/${dutchRoutes.ROUTE_CONTACT}`;
    ROUTE_MASSAGE = `/${dutchRoutes.ROUTE_MASSAGE}`;
  }
};

export const setTitle = title => {
  console.log('title');
}

export const checkRoutes = () => {
  // let route = history.location.pathname.substring(1);
  // console.log(route);
  // console.log(Object.values(dutchRoutes));
  // console.log(Object.values(frenchRoutes));
  // if (Object.values(frenchRoutes).indexOf(route) > -1) {
  //   console.log("IS FRENCH");
  //   history.push(
  //     Object.values(dutchRoutes)[Object.values(frenchRoutes).indexOf(route)]
  //   );
  //   localStorage.setItem("language", "fr");
  //   ROUTE_TREATMENT = `/${frenchRoutes.ROUTE_TREATMENT}`;
  //   ROUTE_TREATMENT_DETAIL = ROUTE_TREATMENT + "/:treatment";
  //   ROUTE_CONTACT = `/${frenchRoutes.ROUTE_CONTACT}`;
  // } else if (Object.values(dutchRoutes).indexOf(route) > -1) {
  //   console.log("IS DUTCH");
  //   history.push(
  //     Object.values(frenchRoutes)[Object.values(dutchRoutes).indexOf(route)]
  //   );
  //   localStorage.setItem("language", "nl");
  //   ROUTE_TREATMENT = `/${dutchRoutes.ROUTE_TREATMENT}`;
  //   ROUTE_TREATMENT_DETAIL = ROUTE_TREATMENT + "/:treatment";
  //   ROUTE_CONTACT = `/${dutchRoutes.ROUTE_CONTACT}`;
  // }
};

export { ROUTE_APP, ROUTE_TREATMENT, ROUTE_TREATMENT_DETAIL, ROUTE_CONTACT, ROUTE_KINE, ROUTE_MASSAGE };

export const route = (route, params) => {
  let RegularExpression = new RegExp(Object.keys(params).join("|"), "gi");
  route = route.replace(RegularExpression, function (matched) {
    if (params[matched]) {
      return params[matched.toLowerCase()]
        .toString()
        .toLowerCase()
        .replace(/\s/g, "-");
    }
  });

  return route;
};
