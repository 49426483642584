export default {
  "general.read_more": "Lees meer",

  "home.intro.first-line": "Kinesitherapie bij Helix.",
  "home.intro.second-line": null,
  "home.intro.button.left": "Maak een afspraak",
  "home.intro.button.right": "Ontdek mijn behandelingen",

  "navigation.home": "Kine",
  "navigation.treatments": "Behandelingen",
  "navigation.contact": "Contact",
  "contact.phone": "+32 471 87 18 93",
  "contact.street": "Kerkplein 15, 9600 Ronse",
  "contact.mail": "celine@helixronse.be",

  "treatments.title": "Mijn <span class='d-inline-block'>behandelingen</span>",
  "treatments.other": "Andere behandelingen",
  "treatments.all": "Alle behandelingen",

  "treatments.kine_revalidation": "Oncologische revalidatie",
  "treatments.kine_revalidation.route": "oncologische-revalidatie",

  "treatments.kine_revalidation.title": "Hoe verloopt revalidatie?",
  "treatments.kine_revalidation.description":
    "Hoe revalidatiebehandeling eruitziet, hangt af van uw specifieke situatie. Afhankelijk van het onderzoek, uw wensen en uw mogelijkheden stellen we samen een behandelplan op.",

  "treatments.pregnancy": "Zwangerschaps<span class='d-inline-block'>begeleiding</span>",
  "treatments.pregnancy.route": "zwangerschapsbegeleiding",
  "treatments.pregnancy.title": "Prenatale kinesitherapie",
  "treatments.pregnancy.description":
    "Het begeleiden van de zwangere vrouw en een goede voorbereiding garanderen bij de bevalling is heel belangrijk om de zwangerschap zo aangenaam mogelijk te laten verlopen.\r\n\nEr wordt onder andere aandacht geschonken aan houdingscorrecties, het trainen van bekkenbodemmusculatuur om zwangerschapsincontinentie tegen te gaan, het geven van tips om weeën op te vangen, het aanleren van verschillende ademhalingstechnieken en zoveel meer.",
  "treatments.pregnancy.second_title": "Postnatale kinesitherapie",
  "treatments.pregnancy.second_description":
    "Door de zwangerschap is het lichaam gedeconditioneerd en uit balans geraakt. Postnatale begeleiding streeft ernaar het lichaam weer in evenwicht brengen door zowel de fysieke conditie als de mentale belevingen te ondersteunen.\r\nZo versterken we onder meer de bekkenbodemspieren, de rug-en buikspieren.",

  "treatments.reeducation": "Bekkenbodem<span class='d-inline-block'>reëducatie</span>",
  "treatments.reeducation.route": "bekkenbodemreëducatie",
  "treatments.reeducation.third_title": "Wanneer bekkenbodemreeducatie?",
  "treatments.reeducation.third_description":
    "Patiënten met volgende klachten kunnen beroep doen op een bekkenbodemtherapeut:\r\n\n • Urge-incontinentie: Een plotse plasdrang\r\n • Stressincontinentie: Urinair inspanningsverlies\r\n • Stoelgangsverlies\r\n • Moeilijk plassen\r\n • Enuresis of bedplassen\r\n • Verzakkingen van de blaas, darmen of baarmoederverzakking\r\n • Incontinentie na prostatectomie\r\n • Seksuele problemen zoals pijnlijke betrekkingen of dyspareunie, hypertonie etc.",
  "treatments.reeducation.title":
    "Pelvische reëducatie of bekkenbodemreëducatie",
  "treatments.reeducation.image":
    "../../assets/img/foot_therapy.jpg",
  "treatments.reeducation.description":
    "Binnen het vakgebied van de kinesitherapie omhelst de pelvische reëducatie de aanpak van verschillende disfuncties in het gebied van het kleine bekken of pelvis.\r\n\nDeze problemen kunnen zich op velerlei manieren manifesteren denken we maar aan urineverlies. Urinair inspanningsverlies is één van de meest voorkomende symptomen van deze disfunctie, en zeker een goede indicatie voor behandeling.",

  "treatments.lymphe": "Lymfe<span class='d-inline-block'>drainage</span>",
  "treatments.lymphe.route": "lymfedrainage",
  "treatments.lymphe.title": "Wat is lymfedrainage?",
  "treatments.lymphe.description":
    "Manuele Lymfedrainage is een zachte behandeltechniek gericht op het stimuleren van het lymfestelsel door een trage, zachte en ritmische druk op de lymfeklieren en lymfebanen.\r\n\nDe richting van die druk wordt bepaald door de stroomrichting van het lymfestelsel waardoor het teveel aan vocht of lymfe beter en sneller kan worden afgevoerd.\r\n\nDeze techniek is op het gehele lichaam toepasbaar en kan gebruikt worden voor heel wat uiteenlopende klachten zoals:\nZwellingen na bijvoorbeeld een operatie of borstamputatie, verstuikingen, hoofdpijn, spierletsels, circulatiestoornissen etc.",

  "treatments.quote":
    "Als kinesitherapeute specialiseerde ik me in een aantal gebieden waar uw zorg steeds centraal staat.",

  "treatments.kine_revalidation.summary":
    "Optimale genezing nastreven door individuele begeleiding naargelang de capaciteit van de patiënt.",
  "treatments.pregnancy.summary":
    "Perinatale begeleiding is belangrijk om de periode rond de bevalling en geboorte zo aangenaam mogelijk te beleven.",
  "treatments.reeducation.summary":
    " Bekkenbodemkine is een methode waarbij u de spieren van de bekkenbodem leert te voelen en bewust te gebruiken, zowel ter versterking als ter relaxatie.",
  "treatments.lymphe.summary":
    " Lymfedrainage is een zachte massagetechniek met als doel het teveel aan vocht beter en sneller via de de lymfbanen af te voeren.",
  "introduction.practical": "Praktische info",
  "introduction.practical.appointment": "Afspraken",
  "introduction.practical.appointment.description":
    "Behandelingen gebeuren uitsluitend op afspraak. U kan telefonisch een afspraak maken op het nummer 0471/87.18.93 of mailen naar info@celinecharlier.be. Gelieve steeds tijdig aanwezig te zijn op uw afspraak. Indien u te laat bent kan de behandeling niet langer duren. Bij het niet afbellen van de afspraak, zal de consultatie aangerekend worden.",
  "introduction.practical.prices": "Tarieven",
  "introduction.practical.prices.description":
    "Als geconventioneerd kinesitherapeut wordt er gewerkt met de officiële tarieven die het RIZIV oplegt. De terugbetaling verloopt via uw mutualiteit, hoeveel u terugkrijgt hangt af van uw ziektebeeld en uw sociaal statuut.",
  "introduction.quote":
    "Samen zoeken we naar een optimale behandeling om jouw probleem op te lossen.",
  "about.title": "Wie is Céline Charlier?",
  "about.description":
    "Ik ben in 2008 afgestudeerd als licentiaat of master in de revalidatiewetenschappen en kinesitherapie aan de Universiteit Gent. Naast het regelmatig volgen van congressen en bijscholingen om up to date te blijven ben ik bestuurslid  van de lokale kinekring Ronse – Oudenaarde, nl. kinekring Vlaamse Ardennen.\r\n\nHoewel mijn praktijk een algemene kinesitherapiepraktijk is, is mijn interesse doorheen de jaren gegroeid voor bekkenbodemproblematiek en perinatale begeleiding. Ik behaalde dan ook een bijzondere bekwaamheid in deze materie.\r\n\nDoor mijn eigen interesse in sport en beweging, probeer ik ook een aangename sfeer te creëren voor mensen die onder persoonlijke begeleiding wensen te bewegen, zowel perinataal als tijdens een revalidatie (oncologische revalidatie, cardiale revalidatie,…).",

  "contact.form.title": "Stel uw vraag hier of <span class='d-inline-block'>verzoek</span> een afspraak.",
  "contact.form.submit": "Bericht verzenden",

  "footer.navigation": "Navigatie",
  "footer.home": "Home",
  "footer.treatments": "Behandelingen",
  "footer.contact": "Contact",
  "footer.number.title": "Ondernemingsnummer",
  "footer.number": "0826 203 636",
  "footer.riziv.title": "RIZIV",
  "footer.riziv": "537 194 89 527",

  "selection.kine": "Kinesitherapie",
  "selection.massage": "Zorgmassage",
};
