import React, { Component, Fragment } from "react";

import { ReactComponent as CurveBlock } from "../../assets/curve-block.svg";
import FadeIn from "../shared/animations/fadeIn";
import FadeInUp from "../shared/animations/fadeInUp";
import GoogleMap from "../shared/map/mapDiv";
import VisibilitySensor from "react-visibility-sensor";
import { renderHtml } from '../../core/helpers';
import { t } from "../../core/localization/source";

export default class ContactScene extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: ""
    };
  }

  setUser = user => {
    this.setState({
      user: user
    });
  };

  render() {
    return (
      <section className="row m-0 pt-5 mb-5 pb-5 position-relative text-primary contact-page first-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="position-relative">
                <VisibilitySensor>
                  {({ isVisible }) => (
                    <Fragment>
                      <FadeInUp
                        once
                        delay="100"
                        fadeIn={isVisible ? true : false}
                      >
                        <h1 dangerouslySetInnerHTML={renderHtml(t("contact.form.title"))}></h1>
                      </FadeInUp>
                      <FadeInUp
                        once
                        delay="500"
                        fadeIn={isVisible ? true : false}
                      >
                        <form
                          className="contact-form row"
                          action="https://formspree.io/info@celinecharlier.be"
                          method="POST"
                        >
                          <div className="col-md-6">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Naam en voornaam*"
                              name="name"
                              onChange={e => this.setUser(e.target.value)}
                              required
                            />
                          </div>
                          <div className="col-md-6">
                            <input
                              className="form-control"
                              type="email"
                              placeholder="Email*"
                              name="_replyto"
                              required
                            />
                          </div>
                          <div className="col-md-6">
                            <input
                              className="form-control"
                              type="tel"
                              placeholder="Telefoon of GSM*"
                              name="tel"
                              required
                            />
                          </div>
                          <div className="col-md-6">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Bedrijfsnaam"
                              name="company"
                            />
                          </div>
                          <div className="col-md-12 mb-5">
                            <select className="form-control" name="_subject">
                              <option value={`${this.state.user}: Afspraak`}>
                                Afspraak
                              </option>
                              <option value={`${this.state.user}: Vraag`}>
                                Vraag
                              </option>
                            </select>
                          </div>
                          <div className="col-12">
                            <textarea
                              className="form-control"
                              placeholder="Uw vraag of opmerking*"
                              name="remark"
                              rows="4"
                              required
                            />
                          </div>
                          <div className="col-12">
                            <input
                              className="btn btn-primary"
                              type="submit"
                              value={t("contact.form.submit")}
                            />
                          </div>
                        </form>
                      </FadeInUp>
                    </Fragment>
                  )}
                </VisibilitySensor>
              </div>
            </div>
            <FadeIn>
              <div
                id="map"
                className="d-none d-lg-flex col-lg-6 map min-half-height align-items-center justify-content-end"
              >
                <GoogleMap />
                <CurveBlock
                  preserveAspectRatio="none"
                  className="contact-curve fill-white"
                />
              </div>
            </FadeIn>
          </div>
          <div className="d-flex d-lg-none col-lg-6 mobile-map bg-secondary min-half-height align-items-center justify-content-end">
            <GoogleMap />
            <CurveBlock
              preserveAspectRatio="none"
              className="contact-curve fill-white"
            />
          </div>
        </div>
      </section>
    );
  }
}
