import { ROUTE_TREATMENT } from "../../core/routing/routing";
import React, { Fragment } from "react";

import { ReactComponent as Curve } from "../../assets/curve.svg";
import { ReactComponent as CurveSecondary } from "../../assets/curve-secondary.svg";
import FadeIn from "../shared/animations/fadeIn";
import FadeInLeft from "../shared/animations/fadeInLeft";
import FadeInUp from "../shared/animations/fadeInUp";
import Icon from "../shared/icon/Icon";
import { Link } from "react-router-dom";
import VisibilitySensor from "react-visibility-sensor";
import { t } from "../../core/localization/source";

export default function Fold({
  title = t("home.intro.first-line"),
  subtitle = t("home.intro.second-line"),
  phone = t("contact.phone"),
  mail = t("contact.mail"),
  color = "primary",
  appointment = false,
  treatments = false,
  introduction = false,
  imageVariant = "fold-bg-image--kine" }) {
  return (
    <section className={`min-full-height row m-0 position-relative ${color}`}>
      <div className="col-md-5 d-none p-0 d-md-flex justify-content-end">
        <FadeIn delay="1000">
          <div className={`fold-bg-image ${imageVariant}`}></div>
        </FadeIn>
        <Curve className="fill-white fold-mid-curve" />
      </div>
      <div className="offset-md-1 col-md-6 mt-md-5 pt-md-5 mb-md-5 pb-md-5 pl-5 pr-5 d-flex align-items-center justify-content-center">
        <div className="d-flex flex-column align-self-lg-end mt-5 mb-5 pb-lg-5">
          <VisibilitySensor>
            {({ isVisible }) => (
              <Fragment>
                <FadeInUp once fadeIn={isVisible ? true : false}>
                  <h1 className="mt-5">
                    {title}
                    <br />
                    {subtitle}
                  </h1>
                </FadeInUp>

                {
                  introduction &&
                  <FadeInUp once fadeIn={isVisible ? true : false}>
                    <p style={{ maxWidth: 450 }}>
                      {introduction}
                    </p>
                  </FadeInUp>
                }
                <div>
                  <ul className="icon-list">
                    <FadeInLeft
                      delay="500"
                      once
                      fadeIn={isVisible ? true : false}
                    >
                      <li>
                        <a
                          href={`tel:${phone}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="link-unstyled d-flex"
                        >
                          <span>
                            <Icon name="phone" />
                          </span>
                          <span>{phone}</span>
                        </a>
                      </li>
                    </FadeInLeft>
                    <FadeInLeft
                      delay="600"
                      once
                      fadeIn={isVisible ? true : false}
                    >
                      <li>
                        <a
                          href="https://goo.gl/maps/FW6JFCAf5tPSc5w86"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="link-unstyled d-flex"
                        >
                          <span>
                            <Icon name="navigation" />
                          </span>
                          <span> {t("contact.street")}</span>
                        </a>
                      </li>
                    </FadeInLeft>
                    <FadeInLeft
                      delay="700"
                      once
                      fadeIn={isVisible ? true : false}
                    >
                      <li>
                        <a
                          href={`mailto:${mail}`}
                          className="link-unstyled d-flex"
                        >
                          <span>
                            <Icon name="mail" />
                          </span>
                          <span> {mail}</span>
                        </a>
                      </li>
                    </FadeInLeft>
                  </ul>
                </div>
              </Fragment>
            )}
          </VisibilitySensor>
          <div className="d-flex flex-column flex-sm-row flex-wrap mt-3 mb-3 mb-md-5 fold-buttons">
            {
              appointment && (
                <Link
                  to={{ pathname: appointment }}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-primary d-flex align-items-center mt-3 mr-2"
                >
                  {t("home.intro.button.left")}
                </Link>
              )
            }
            {
              treatments &&
              <Link
                to={ROUTE_TREATMENT}
                className="btn btn-tertiary d-flex align-items-center mt-3 mr-2"
              >
                {t("home.intro.button.right")}
              </Link>
            }
          </div>
        </div>
      </div>
      <CurveSecondary className={`fold-bottom-curve`} />
    </section>
  );
}
