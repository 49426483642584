import React, { Component, Fragment } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import HomeScene from "./components/home/HomeScene";
import ContactScene from "./components/contact/ContactScene";
import NoMatch from "./components/404/NoMatch";
import TreatmentDetail from "./components/treatment/detail/TreatmentDetail";
import TreatmentOverview from "./components/treatment/overview/TreatmentOverview";
import Nav from "./components/shared/nav/Nav";
import Footer from "./components/shared/footer/Footer";
import { initLocalizations } from "./core/localization/localization";
import {
  ROUTE_APP,
  ROUTE_TREATMENT,
  ROUTE_TREATMENT_DETAIL,
  ROUTE_CONTACT,
  ROUTE_KINE,
  ROUTE_MASSAGE,
  setLanguage,
  checkRoutes,
  history
} from "./core/routing/routing";
import Message from "./components/shared/message/Message";
import SelectorScene from "./components/selector/SelectorScene";
import MassageScene from "./components/massage/MassageScene";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import Icon from "./components/shared/icon/Icon";


const KineLayout = ({ children, toggleLang, isDutch }) => (
  <Fragment>
    <Nav activePath={history.location.pathname} changeLanguage={toggleLang} isDutch={isDutch} />
    {children}
    <Footer />
  </Fragment>
);

const MassageLayout = ({ children, toggleLang, isDutch }) => (
  <div>
    <nav className="nav" id="navbar">
      <NavLink
        exact
        to={ROUTE_APP}
        activeClassName="selected"
        className="logo text-decoration-none"
        title="Céline Charlier | Kinesitherapie"
      >
        <Icon name="logo" />
      </NavLink>
    </nav>

    {children}
  </div>
);




class App extends Component {
  constructor(props) {
    super(props);
    let language = localStorage.getItem("language");
    switch (language) {
      case "fr":
        this.state = { isDutch: false };
        initLocalizations("fr");
        setLanguage("fr");
        break;
      default:
        this.state = { isDutch: true };
        initLocalizations("nl");
        setLanguage("nl");
        break;
    }
  }

  componentDidMount() {
    fetch("/message.json")
      .then((res) => res.json())
      .then((data) => {
        this.setState({ message: data.message });
      });
  }

  toggleLang = () => {
    this.setState({ isDutch: !this.state.isDutch });
    setLanguage(this.state.isDutch ? "fr" : "nl");
    checkRoutes();
  };

  setLanguageRoute = isDutch => {
    // const currentRoute = history.location.pathname;
    if (isDutch) {
      // SET CURRENT ROUTE
    }
  };

  render() {
    return (
      <BrowserRouter>
        {
          this.state.message &&
          <Message>
            {this.state.message}
          </Message>
        }

        {/* <Switch>
          <Route exact path={ROUTE_APP} component={SelectorScene} />
        </Switch> */}
        <Switch>
          <Route exact path={ROUTE_APP} component={SelectorScene} />

          <Route path={ROUTE_KINE}>
            <KineLayout toggleLang={this.toggleLang} isDutch={this.isDutch}>
              <Switch>
                <Route path={ROUTE_TREATMENT_DETAIL} component={TreatmentDetail} />
                <Route path={ROUTE_TREATMENT} component={TreatmentOverview} />
                <Route path={ROUTE_CONTACT} component={ContactScene} />
                <Route path={ROUTE_KINE} component={HomeScene} />
              </Switch>
            </KineLayout>
          </Route>

          <Route path={ROUTE_MASSAGE}>
            <MassageLayout>
              <Route exact path={ROUTE_MASSAGE} component={MassageScene} />
            </MassageLayout>
          </Route>

          <Route component={NoMatch} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
