import React, { Component } from "react";
import Introduction from "./Introduction";
import Fold from "./Fold";
import About from "./About";

export default class HomeScene extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     animated: false
  //   }
  // }

  // componentDidMount = () => {
  //   this.timeout = setTimeout(() => {
  //     this.setState({
  //       animated: true
  //     })
  //   }, 1200);
  // }

  // componentWillUnmount = () => {
  //   clearTimeout(this.timeoout);
  // }


  render() {
    return (
      <div>
        <Fold mail="celine@helixronse.be" appointment="https://onlinebooking.myorganizer.online/?guid=Mjc5MDk2NmYtMDZkOC00NWMyLTk2ZDctZjlkNjdiN2Y1NmIz&label=MTE3Njk%3D&language=bmxfQkU%3D&role=OA%3D%3D" treatments={true} introduction="Céline Charlier biedt kinesitherapie op maat aan, afgestemd op uw specifieke klachten en behoeften." imageVariant="" />
        <Introduction />
        <About />
      </div>
    );
  }
}
