import React from 'react'
import Fold from '../home/Fold'

const MassageScene = () => {
    return (
        <Fold
            color="primary-light"
            title="Zorgmassage bij Helix."
            phone="+32 471 41 54 22"
            mail="debby@helixronse.be"
            introduction="Debby De Vos is een gediplomeerde zorgmassage therapeute, die zich richt op het bevorderen van uw fysieke en mentale welzijn."
            appointment="https://salonkee.be/salon/zorgmassagetherapeut?search=kappers-en-schoonheidssalons"
            imageVariant='fold-bg-image--massage' />
    )
}

export default MassageScene