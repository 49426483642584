export const dutchRoutes = {
  ROUTE_TREATMENT: "behandelingen",
  ROUTE_CONTACT: "contact",
  ROUTE_KINE: "kinesitherapie",
  ROUTE_MASSAGE: "massage",
};
export const frenchRoutes = {
  ROUTE_TREATMENT: "treatements",
  ROUTE_CONTACT: "contactez-nous",
  ROUTE_KINE: "physiotherapie",
  ROUTE_MASSAGE: "massage",
};
